.bg-login {
  background-color: #F1F5F9;
  background-size: contain;

  background-position: 50% 20%;
}

.bg-login1 {
  content: url(../../assets/img/Login\ Left\ Panel.png);
  padding: 5px;
  height: 100vh;
}

.card {
  z-index: 5;
  border-radius: 24px;
}

.bg-img {
  z-index: 15;
  position: absolute;
  vertical-align: middle;
}

/* .card {
  padding: 15px;
} */


.login-form-bg {
  content: url(../../assets/img/Rectangle\ 885.png);
  position: fixed;
  z-index: 0;
  margin-top: -120px;
  margin-left: 350px;
  width: 268.71px;
  height: 268.71px;
  /* left: 648.63px; */
  /* top: 592.97px; */
}

.m-t {
  margin-top: -45px;
  right: 0;
  padding: 5px 9px 5px 8px;
  /* margin-left: 390px; */
}

/* {
  content: 
} */

.prpl {
  color: #5945B3;
  font-weight: 500;
}

.login-head:hover {
  text-decoration: none;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}


#modal .modal-body {
  /* background-color: #fff; */
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.otp-letter-input {
  max-width: 100%;
  height: 50px;
  border: 1px solid #198754;
  border-radius: 10px;
  color: black;
  font-size: 40px;
  text-align: center;
}

.btn {
  height: 50px;
}

.otp-letter-input input[type="number"]::-webkit-inner-spin-button,
.otp-letter-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox has different pseudo-elements for the spin buttons */
.otp-letter-input input[type="number"] {
  -moz-appearance: textfield;
}

.otp-letter-input input[type="number"]::-moz-number-spin-box {
  -moz-appearance: none;
}