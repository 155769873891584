.custom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 600;
}

.custom-col {
  flex: 0.6;
}

.accordion-button:not(.collapsed) {
  color: rgb(94, 110, 130);
}

.accordion-button:focus {
  color: inherit;
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.modal {
  background: rgb(0 0 0/0.3);
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Inter", sans-serif;
}

.zeconcile-table a {
  color: #5945b3;
  text-decoration: none;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.zeconcile-table {
  width: 100%;
  border: 1px solid #cbd5e1;
  border-radius: 10px;
  border-spacing: 0;
  text-align: center;
  border-collapse: separate;
}

.zeconcile-table th {
  color: #64748b;
  background: #f1f5f9;
}

.zeconcile-table th:last-of-type {
  border-top-right-radius: 10px;
}

.zeconcile-table th:first-of-type {
  border-top-left-radius: 10px;
}

.zeconcile-table td,
.zeconcile-table th {
  border-bottom: 1px solid #cbd5e1;
  padding: 10px;
   /* //12px 15px; */
  font-size: 14px;
}

.zeconcile-table td p,
.zeconcile-table th p {
  margin: 0;
}

.zeconcile-table tr:last-of-type td {
  border: none;
}

.zeconcile-table th {
  background-color: #F1F5F9;
}

.zeconcile-table tr:last-child>td {
  border-bottom: none;
}

.zeconcile-table td.with-chip {
  display: flex;
  justify-content: center;
  align-items: center;
}

.zeconcile-chip {
  display: flex;
  justify-content: center;
  background: #ecfdf5;
  border-radius: 40px;
  align-items: center;
  gap: 5px;
  padding: 5px 14px;
  width: max-content;
  font-weight: 500;
}

.zeconcile-chip .dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.zeconcile-chip.success {
  background-color: #ecfdf5;
  color: #10b981;
}

.zeconcile-chip.success .dot {
  background-color: #10b981;
}

.zeconcile-chip.danger {
  background: #fef2f2;
  color: #ef4444;
}

.zeconcile-chip.danger .dot {
  background-color: #ef4444;
}

.zeconcile-btn,
.zeconcile-btn.btn:hover {
  background: #5945b3;
  border-radius: 12px;
  padding: 12px 12px 12px 16px;
  display: inline-block;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.zeconcile-btn .transparent {
  border: 1px solid #cbd5e1;
  background-color: #fff;
  color: #64748b;
}
.zeconcile-btn.transparent2 {
  border: 1px solid #cbd5e1;
  background-color: #fff;
  color: #64748b;
}
.zeconcile-btn.transparent2:hover {
  background: #EDEAF7;
    border-radius: 12px;
    padding: 12px 12px 12px 16px;
    display: inline-block;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.zeconcile-btn.lite {
  border: 1px solid #cbd5e1;
  background-color: #CBD5E1;
  padding: 12px 80px;

  color: #fff;
}

.zeconcile-btn.lite:hover {
  border: 1px solid #cbd5e1;
  background: #5945b3;
  padding: 12px 80px;
  color: #fff;
}

.zeconcile-btn.purple {
  background: #edeaf7;
  border: 1px solid #a398d5;
  color: #5945b3;
}

.zeconcile-btn.green {
  background: #10b981;
  color: #ffffff;
}

/* common breadcrumb */

.zc-breadcrumb {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #64748B;
  gap: 10px;
  margin-top: 20px;
}

.zc-breadcrumb .item-breadcrumb {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  line-height: 21px;
  text-decoration: none;
}

.zc-breadcrumb .item-breadcrumb.active {
  color: #5945B3;
  font-weight: 700;
}

.zc-page-title {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 23px;
  color: #475569;
}

.zc-search-invoices {
  min-height: 55px;
}

.label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px;
}

.page-item.active.page-link {
  z-index: 3;
  color: unset !important;
  /* background-color: unset ; */
  border-color: none;
  /* margin: 0 15px; */
  padding: 12px;
  border-radius: 15px;
}

.page-link {
  z-index: 3;
  /* color: unset !important; */
  /* background-color: unset ; */
  border-color: none;
  margin: 0 10px;
  padding: 0.5rem 0.75rem;
  border-radius: 15px;
  font-size: 14px;
}

.page-item.active .page-link {
  background-color: #5945B3;
  border-radius: 12px;
  color: white;
}

.zeconcile-btn {
  gap: 12px;
}

/* .zeconcile-btn:hover {
  background: var(--falcon-pagination-active-bg);
  border-radius: 12px;
  padding: 12px 12px 12px 16px;
  display: inline-block;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
} */

.page-item:not(:first-child) .page-link {
  margin-left: unset;
}

span.page-link:hover {
  background: #EDEAF7;
  border: none;
  color: #5945B3;
}

.page-item .page-link {
  border-radius: 12px;
  min-width: 30px;
}